<script setup>
import Icon from '@components/Icon.vue'
import Spinner from '@components/Spinner.vue'
import {ref, reactive, onMounted, onUnmounted} from 'vue'
import { $exitIntentOpen } from '../store'
import Cookies from 'js-cookie'
import api from '@helpers/api'

const idleInterval = ref(null)
const idleTime = ref(0)
const modalOpen = ref(false)
const submitted = ref(false)
const submitting = ref(false)
const errorMessage = ref('')
const form = reactive({
  list_id: 'WJkvb6',
  email: '',
  name: '',
})

onMounted(() => {
  if (!Cookies.get('exit_intent_submitted') && !$exitIntentOpen.get()) {
    setTimeout(() => {
      document.addEventListener('mouseout', (e) => {
        if (!e.toElement && !e.relatedTarget) {
          open()
        }
      })

      document.addEventListener('visibilitychange', (e) => {
        if (document.hidden) {
          open()
        }
      })

      window.addEventListener('pagehide', (e) => {
        open()
      })
    }, 30000)

    document.addEventListener('mousemove', () => {
      idleTime.value = 0
    })

    idleInterval.value = setInterval(() => {
      if (idleTime.value >= 30) {
        open()
      }
      idleTime.value++
    }, 1000);
  }
})

onUnmounted(() => {
  if (idleInterval.value) {
    clearInterval(idleInterval.value)
  }
})

function open() {
  if (!$exitIntentOpen.get()) {
    $exitIntentOpen.set((new Date()).toString())
    modalOpen.value = true
  }
}

function subscribe() {
  submitted.value = false
  submitting.value = true
  api
      .post(`/subscribe`, form)
      .then(({ data }) => data)
      .then((data) => {
        submitted.value = true
        Cookies.set('exit_intent_submitted', (new Date()), 60 * 60 * 24 * 365)
      })
      .catch((error) => {
        errorMessage.value = error?.response?.data?.message || 'An unexpected error occurred.'
      })
      .finally(() => {
        submitting.value = false
      })
}
</script>

<template>
  <div class="exit">
    <div class="exit__modal" v-if="modalOpen">
      <div class="exit__backdrop"></div>

      <div class="exit__inner">
        <div class="exit__image">
          <a href="#" class="exit__close" @click.prevent="modalOpen = false" aria-label="Close">
            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" viewBox="0 0 36 36" fill="none">
              <circle cx="18" cy="18" r="18" fill="black" fill-opacity="0.5"/>
              <path d="M24.1885 12L11.9117 24.2768" stroke="white" stroke-width="2"/>
              <path d="M24.1885 24.1958L12.1556 12.1629" stroke="white" stroke-width="2"/>
            </svg>
          </a>

          <img src="/images/exitintent.png" alt="Live rosin gummies" />
        </div>
        <form class="exit__content" @submit.prevent="subscribe">
          <template v-if="submitted">
            <span class="exit__title">Thanks for signing up!</span>
            <br />
            <a href="/shop" class="exit__button">START BROWSING</a>
            <a href="/c/best-sellers" class="exit__button">SHOP BEST SELLERS</a>
          </template>
          <template v-else>
            <span class="exit__title">$10 OFF</span>
            <span class="exit__description">...But The Clock is Ticking...<br /><br />Claim your discount below and save when you order within the next hour.</span>

            <input v-model="form.email" type="text" placeholder="Enter email address" class="exit__input">
            <input v-model="form.name" type="text" placeholder="Name" class="exit__input">

            <div v-if="errorMessage" class="error">
              <Icon type="error" />
              {{ errorMessage }}
            </div>

            <button type="submit" class="exit__button" :disabled="submitting">
              <Spinner color="white" v-if="submitting" />
              <span v-else>GET MY DISCOUNT</span>
            </button>
          </template>
        </form>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.exit {
  .exit__modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2147483646;

    .exit__backdrop {
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0.7;
      background-color: rgb(0, 0, 0);
    }

    .exit__inner {
      position: absolute;
      top: 10%;
      left: 50%;
      width: 90%;
      max-width: 500px;
      transform: translateX(-50%);

      & > * {
        flex: 1;
      }

      .exit__image {
        position: relative;

        .exit__close {
          position: absolute;
          top: 12px;
          right: 12px;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 2;

          svg {
            width: 36px;
            height: 36px;
          }
        }

        img {
          display: block;
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .exit__content {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background: #F1EFE8;
        padding: 24px;
        border-radius: 0 0 8px 8px;

        .exit__title {
          display: block;
          color: #000;
          font-size: 34px;
          line-height: 38px;
          font-family: Outfit, serif;
          font-weight: 700;
          text-align: center;
          margin-bottom: 16px;
        }

        .exit__description {
          display: block;
          color: var(--Dark-navy, #102B38);
          font-size: 18px;
          line-height: 24px;
          margin-bottom: 32px;
          text-align: center;
          font-weight: 400;
          font-family: Outfit, serif;

          @media screen and (min-width: 768px) {
            font-size: 20px;
          }
        }

        .exit__input {
          border-radius: 12px;
          font-size: 14px;
          border: 0;
          background: #fff;
          padding: 12px 20px;
          width: 100%;
          margin-bottom: 12px;
          font-family: Outfit, serif;
          color: #8A8A8A;
        }

        .exit__button {
          background: #DB7E5A;
          border-radius: 12px;
          color: #fff;
          font-family: Outfit, serif;
          border: 0;
          width: 100%;
          padding: 12px;
          font-weight: 600;
          cursor: pointer;
          display: block;
          text-align: center;
          text-decoration: none;
          margin-bottom: 12px;
        }
      }
    }
  }
}
</style>
